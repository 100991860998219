@import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');

body{
  overflow: hidden
}

body:before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-image: url("./../public/img/jumbotron-birthday.jpg");
    background-size: cover;
    background-position: center center;
    z-index: -1;
}
.main-jumbotron{
    background-color: transparent !important;
}

.main-jumbotron{
    position: relative;
    font-weight: bold;
    
}
.main-jumbotron-images{
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 25%;
  z-index: 2;
  opacity: .95
}
.main-jumbotron .card{
    background-color: rgba(0,0,0, 0.1);
    
}
.main-jumbotron .col-timer{
    padding-right: 5px;
    padding-left: 5px;
}
.main-jumbotron h1{
    font-family: 'Inspiration', cursive;
    font-size: 120px
}
.main-jumbotron .container-fluid{
    position: relative;
}
.jumbotron{
    xborder-bottom: 10px solid rgb(247, 7, 7);
}


.confetti-container{
    position: absolute;
    z-index: 2
}
.confetti-container-lt{
    left:0;
    top:0;
}
.confetti-container-rb{
    right:0;
    bottom:0;
}
.confetti-container-rt{
    right: 0;
    top:0;
}
.confetti-container-lb{
    left:0;
    bottom: 0;
}
.confetti-container-cc{
    left:50%;
    top: 45%;
}
img.react-reveal{
  border: 10px solid #f5a3fb;
  border-radius: 25px;
}